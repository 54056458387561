import React from 'react';
import './error404.style.scss';

export const Error404 = () => {

  return (
    <section className='error404'>
      404
    </section>
  )
}